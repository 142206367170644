import React from "react";
import { FaExpand, FaHome, FaBarcode, FaAddressCard, FaGripLinesVertical, FaQuestion } from 'react-icons/fa';
import { Jumbotron } from 'react-bootstrap';
import { Helmet } from "react-helmet";




export const NoMatch = () => (
    <>
        <Helmet>
            <title>404 Page Not Found | Oberhof Drinks®</title>
            <meta name="description" content="404 - Page Not Found" />
            <meta name="keywords" content="404, Page Not Found" />
        </Helmet>

        <div>
            <Jumbotron style={{
                width: '100%',
                textAlign: 'center',
                margin: '40px auto 30px auto',
                boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
            }}>
                <h1 class="text-muted">Oberhof Drinks®</h1>

                <p class="text-muted">
                    You are here <FaExpand title="404 - Page not Found" /> In the middle of nowhere aka 404 Page not Found</p>
                <h3><a href="/"><FaHome title="Home" /></a> <FaGripLinesVertical title="just lines" /> <a href="/Products"><FaBarcode title="Products" /></a> <FaGripLinesVertical title="just lines" /> <a href="/Faq"><FaQuestion title="FAQ" /></a> <FaGripLinesVertical title="just lines" /> <a href="/AboutUs"><FaAddressCard title="About us" /></a></h3>
                <p class="text-muted">It's all Juice and all about the Juice.</p>
            </Jumbotron>
        </div>

    </>
)
