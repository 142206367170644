import React, { Component } from 'react';
import { BrowserRouter as Router, Route, Switch } from 'react-router-dom';
import { Home } from './Home';
import { AboutUs } from './AboutUs';
import { Faq } from './Faq';
import { Test } from './Test';
import { Products } from './Products';
import { PrivacyPolicy } from './PrivacyPolicy';
import { NoMatch } from './NoMatch';
import { Layout } from './components/Layout';
import { NavigationBar } from './components/NavigationBar';
import { Footer } from './components/Footer';
import 'bootstrap/dist/css/bootstrap.min.css';

class App extends Component {
  render() {
    return (
      <React.Fragment>
        <Router>
          <NavigationBar />
          <Layout>
            <Switch>
              <Route exact path="/" component={Home} />
              <Route path="/AboutUs" component={AboutUs} />
              <Route path="/Products" component={Products} />
              <Route path="/Faq" component={Faq} />
              <Route path="/Test" component={Test} />
              <Route path="/PrivacyPolicy" component={PrivacyPolicy} />
              <Route component={NoMatch} />
            </Switch>
            <Footer />
          </Layout>
        </Router>
      </React.Fragment>
    );
  }
}

export default App;
