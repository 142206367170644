import React from 'react';
import { Nav, Navbar } from 'react-bootstrap';
import { FaHome, FaBarcode, FaQuestion, FaAddressCard } from 'react-icons/fa';


export const NavigationBar = () => (
    <Navbar sticky="top" bg="#ffff" expand="lg">
      <Navbar.Brand href="/" title="Home"><h2><FaHome title="Home" /></h2></Navbar.Brand>
      <Navbar.Toggle />
      <Navbar.Collapse>
        <Nav className="ml-auto">
            <Nav.Link href="/Products" title="Products"><h3><FaBarcode title="Products" /></h3></Nav.Link>
            <Nav.Link href="/Faq" title="Faq"><h4><FaQuestion title="F | A | Q" /></h4></Nav.Link>
            <Nav.Link href="/AboutUs" title="Company | Address | Corporation"><h3><FaAddressCard title="About" /></h3></Nav.Link>
            <Nav.Link href="https://de.oberhofdrinks.com" title="Für Deutsch"><h3>de</h3></Nav.Link>
          </Nav>
          </Navbar.Collapse>
        </Navbar>

)
