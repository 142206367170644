import React from 'react';
import { Container } from 'react-bootstrap';

export const Layout = (props) => (

<>

    <Container>
        {props.children}
    </Container>

    <style jsx global>{`
*,
*::before,
*::after {
  box-sizing: border-box;
}

body {
  margin: 0;
  font-size: 18px;
  line-height: 1.5;
  font-weight: 500;
  background: #fff;
  color: #454545;
  font-family: -apple-system, Roboto, sans-serif;
  text-rendering: optimizeLegibility;
}

a {
  color: #1b789e;
  text-decoration: none;
}

a:hover {
  color: #166281;
}

img {
  max-width: 100%;
}

/* Layout */

.content-wrapper {
  min-height: 600px;
  text-align: center;
} 
`}</style>

</>

)
