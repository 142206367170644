import React from 'react';
import { Tab, Tabs, Container, Row, Col } from 'react-bootstrap'


const appleJt2 = require('./assets/products/2.png'); // with require
const carrotJt2 = require('./assets/products/5.png'); // with require
const mangoJt2 = require('./assets/products/11.png'); // with require
const mixedJt2 = require('./assets/products/14.png'); // with require
const kiwiJt2 = require('./assets/products/7.png'); // with require
const orangeJt2 = require('./assets/products/16.png'); // with require
const pineJt2 = require('./assets/products/23.png'); // with require
const pearJt2 = require('./assets/products/20.png'); // with require
const tomatoJt2 = require('./assets/products/29.jpg'); // with require
const pomegranateJt2 = require('./assets/products/25.png'); // with require


const carrotGlass = require('./assets/products/4.png'); // with require
const appleGlass = require('./assets/products/1.png'); // with require
const kiwiGlass = require('./assets/products/8.png'); // with require
const mangoGlass = require('./assets/products/10.png'); // with require
const mixedGlass = require('./assets/products/13.png'); // with require
const orangeGlass = require('./assets/products/17.png'); // with require
const pearGlass = require('./assets/products/19.png'); // with require
const pineGlass = require('./assets/products/22.png'); // with require
const pomegranateGlass = require('./assets/products/26.png'); // with require
const tomatoGlass = require('./assets/products/28.png'); // with require


const appleNt2 = require('./assets/products/3.png'); // with require
const carrotNt2 = require('./assets/products/6.png'); // with require
const kiwiNt2 = require('./assets/products/9.png'); // with require
const mangoNt2 = require('./assets/products/12.png'); // with require
const mixedNt2 = require('./assets/products/15.png'); // with require
const orangeNt2 = require('./assets/products/18.png'); // with require
const pearNt2 = require('./assets/products/21.png'); // with require
const pineNt2 = require('./assets/products/24.png'); // with require
const pomegranateNt2 = require('./assets/products/27.png'); // with require
const tomatoNt2 = require('./assets/products/30.png'); // with require


const strawberryFt2 = require('./assets/products/31.png'); // with require
const appleFt2 = require('./assets/products/32.png'); // with require
const mixedFt2 = require('./assets/products/33.png'); // with require
const apricotFt2 = require('./assets/products/34.png'); // with require
const mangoFt2 = require('./assets/products/35.png'); // with require
const orangeFt2 = require('./assets/products/36.png'); // with require
const cherryFt2 = require('./assets/products/37.png'); // with require


const appleJt1 = require('./assets/products/appleJt1.png'); // with require
const carrotJt1 = require('./assets/products/carrotJt1.png'); // with require
const kiwiJt1 = require('./assets/products/kiwiJt1.png'); // with require
const mangoJt1 = require('./assets/products/mangoJt1.png'); // with require
const mixedJt1 = require('./assets/products/mixedJt1.png'); // with require
const orangeJt1 = require('./assets/products/orangeJt1.png'); // with require
const pearJt1 = require('./assets/products/pearJt1.png'); // with require
const pineJt1 = require('./assets/products/pineJt1.png'); // with require
const pomegranateJt1 = require('./assets/products/pomegranateJt1.png'); // with require
const tomatoJt1 = require('./assets/products/tomatoJt1.png'); // with require


const appleNt1 = require('./assets/products/appleNt1.png'); // with require
const carrotNt1 = require('./assets/products/carrotNt1.png'); // with require
const kiwiNt1 = require('./assets/products/kiwiNt1.png'); // with require
const mangoNt1 = require('./assets/products/mangoNt1.png'); // with require
const mixedNt1 = require('./assets/products/mixedNt1.png'); // with require
const orangeNt1 = require('./assets/products/orangeNt1.png'); // with require
const pearNt1 = require('./assets/products/pearNt1.png'); // with require
const pineNt1 = require('./assets/products/pineNt1.png'); // with require
const pomegranateNt1 = require('./assets/products/pomegranateNt1.png'); // with require
const tomatoNt1 = require('./assets/products/tomatoNt1.png'); // with require

export const Test = () => (
    <>

        <div><h2>Test</h2></div>

        <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">

            <Tab eventKey="juice" title="| Juice 200ML |">

                <div className="grid">
                    <figure className="effect-bubba">
                        <h5>Apple Juice</h5>
                        <img src={appleJt2} alt="Apple Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Carrot Juice</h5>
                        <img src={carrotJt2} alt="Carrot Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Juice</h5>
                        <img src={mangoJt2} alt="Mango Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mixed Fruit Juice</h5>
                        <img src={mixedJt2} alt="Mixed Fruit Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi Juice</h5>
                        <img src={kiwiJt2} alt="Kiwi Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Juice</h5>
                        <img src={orangeJt2} alt="Orange Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pineapple Juice</h5>
                        <img src={pineJt2} alt="Pineapple Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pear Juice</h5>
                        <img src={pearJt2} alt="Pear Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomato Juice</h5>
                        <img src={tomatoJt2} alt="Tomato Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pomegranate Juice</h5>
                        <img src={pomegranateJt2} alt="Pomegranate Juice | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Package:</h4>
                            <p class="text-muted">Tetra Pak – 200 ML</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Shelf Life:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Shipping | Loading:</h4>
                            <h5>For 200ML Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 27*200 ML – 3600 Case  <br />20 Ft Ctn (14 Pallet) - 27*200 ML – 2250 Case</p>
                        </Col>
                    </Row>
                </Container>

            </Tab>

            <Tab eventKey="nectar" title="| Nectar 200ML |">
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Apple Nectar</h5>
                        <img src={appleNt2} alt="Apple Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Carrot Nectar</h5>
                        <img src={carrotNt2} alt="Carrot Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Nectar</h5>
                        <img src={mangoNt2} alt="Mango Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mixed Fruit Nectar</h5>
                        <img src={mixedNt2} alt="Mixed Fruit Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi Nectar</h5>
                        <img src={kiwiNt2} alt="Kiwi Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Nectar</h5>
                        <img src={orangeNt2} alt="Orange Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pineapple Nectar</h5>
                        <img src={pineNt2} alt="Pineapple Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pear Nectar</h5>
                        <img src={pearNt2} alt="Pear Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomato Nectar</h5>
                        <img src={tomatoNt2} alt="Tomato Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pomegranate Nectar</h5>
                        <img src={pomegranateNt2} alt="Pomegranate Nectar | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>

                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Package:</h4>
                            <p class="text-muted">Tetra Pak – 200 ML</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Shelf Life:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Shipping | Loading:</h4>
                            <h5>For 200ML Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 27*200 ML – 3600 Case  <br />20 Ft Ctn (14 Pallet) - 27*200 ML – 2250 Case</p>
                        </Col>
                    </Row>
                </Container>

            </Tab>

            <Tab eventKey="glass" title="| Glass Bottle 200ML |">
                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Apple Juice</h5>
                        <img src={appleGlass} alt="Apple Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Carrot Juice</h5>
                        <img src={carrotGlass} alt="Carrot Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Juice</h5>
                        <img src={mangoGlass} alt="Mango Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mixed Fruit Juice</h5>
                        <img src={mixedGlass} alt="Mixed Fruit Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi Juice</h5>
                        <img src={kiwiGlass} alt="Kiwi Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Juice</h5>
                        <img src={orangeGlass} alt="Orange Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pineapple Juice</h5>
                        <img src={pineGlass} alt="Pineapple Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pear Juice</h5>
                        <img src={pearGlass} alt="Pear Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomato Juice</h5>
                        <img src={tomatoGlass} alt="Tomato Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pomegranate Juice</h5>
                        <img src={pomegranateGlass} alt="Pomegranate Juice | Glass Bottle | 200ML" />
                        <figcaption>
                            <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Package:</h4>
                            <p class="text-muted">Glass Bottle – 200 ML</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Shelf Life:</h4>
                            <h5>Glass Bottle</h5>
                            <p class="text-muted">18 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Shipping | Loading:</h4>
                            <h5>For 200ML Glass Bottle</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 24*200 ML – 2750 Case <br />20 Ft Ctn (14 Pallet) - 24*200 ML – 1680 Case</p>
                        </Col>
                    </Row>
                </Container>

            </Tab>

            <Tab eventKey="juice1" title="| Juice 1L |">

                <div className="grid">
                    <figure className="effect-bubba">
                        <h5>Apple Juice</h5>
                        <img src={appleJt1} alt="Apple Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Carrot Juice</h5>
                        <img src={carrotJt1} alt="Carrot Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Juice</h5>
                        <img src={mangoJt1} alt="Mango Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mixed Fruit Juice</h5>
                        <img src={mixedJt1} alt="Mixed Fruit Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi Juice</h5>
                        <img src={kiwiJt1} alt="Kiwi Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Juice</h5>
                        <img src={orangeJt1} alt="Orange Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pineapple Juice</h5>
                        <img src={pineJt1} alt="Pineapple Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pear Juice</h5>
                        <img src={pearJt1} alt="Pear Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomato Juice</h5>
                        <img src={tomatoJt1} alt="Tomato Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pomegranate Juice</h5>
                        <img src={pomegranateJt1} alt="Pomegranate Juice | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                </div>
                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Package:</h4>
                            <p class="text-muted">Tetra Pak – 1L <br />Glass Bottle – 1L</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Shelf Life:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Months</p>
                            <h5>Glass Bottle</h5>
                            <p class="text-muted">18 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Shipping | Loading:</h4>
                            <h5>For 1L Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 12*1L – 1680 Case  <br />20 Ft Ctn (14 Pallet) - 12*1L – 800 Case</p>
                            <h5>For 1L Glass Bottle</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*1L – 2750 Case <br />20 Ft Ctn (14 Pallet) - 6*1L – 1680 Case</p>

                        </Col>
                    </Row>
                </Container>
            </Tab>

            <Tab eventKey="nectar1" title="| Nectar 1L |">

                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Apple Nectar</h5>
                        <img src={appleNt1} alt="Apple Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Carrot Nectar</h5>
                        <img src={carrotNt1} alt="Carrot Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Nectar</h5>
                        <img src={mangoNt1} alt="Mango Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mixed Fruit Nectar</h5>
                        <img src={mixedNt1} alt="Mixed Fruit Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Kiwi Nectar</h5>
                        <img src={kiwiNt1} alt="Kiwi Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Nectar</h5>
                        <img src={orangeNt1} alt="Orange Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pineapple Nectar</h5>
                        <img src={pineNt1} alt="Pineapple Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pear Nectar</h5>
                        <img src={pearNt1} alt="Pear Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Tomato Nectar</h5>
                        <img src={tomatoNt1} alt="Tomato Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Pomegranate Nectar</h5>
                        <img src={pomegranateNt1} alt="Pomegranate Nectar | Tetra Pak | 1L" />
                        <figcaption>
                            <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Package:</h4>
                            <p class="text-muted">Tetra Pak – 1L <br />Glass Bottle – 1L</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Shelf Life:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Months</p>
                            <h5>Glass Bottle</h5>
                            <p class="text-muted">18 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Shipping | Loading:</h4>
                            <h5>For 1L Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 12*1L – 1680 Case  <br />20 Ft Ctn (14 Pallet) - 12*1L – 800 Case</p>
                            <h5>For 1L Glass Bottle</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 6*1L – 2750 Case <br />20 Ft Ctn (14 Pallet) - 6*1L – 1680 Case</p>

                        </Col>
                    </Row>
                </Container>
            </Tab>

            <Tab eventKey="flavored" title="| Flavored Drinks 200ML |">

                <div className="grid">

                    <figure className="effect-bubba">
                        <h5>Strawberry Flavored Drinks</h5>
                        <img src={strawberryFt2} alt="Strawberry Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Apple Flavored Drinks</h5>
                        <img src={appleFt2} alt="Apple Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mixed Flavored Drinks</h5>
                        <img src={mixedFt2} alt="Mixed Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Apricot Flavored Drinks</h5>
                        <img src={apricotFt2} alt="Apricot Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Mango Flavored Drinks</h5>
                        <img src={mangoFt2} alt="Mango Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Orange Flavored Drinks</h5>
                        <img src={orangeFt2} alt="Orange Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>
                    <figure className="effect-bubba">
                        <h5>Cherry Flavored Drinks</h5>
                        <img src={cherryFt2} alt="Cherry Flavored Drinks | Tetra Pak | 200ML" />
                        <figcaption>
                            <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                        </figcaption>
                    </figure>

                </div>

                <Container>
                    <Row>
                        <Col sm={3}>
                            <h4>Package:</h4>
                            <p class="text-muted">Tetra Pak – 200 ML</p>
                        </Col>
                        <Col sm={3}>
                            <h4>Shelf Life:</h4>
                            <h5>Tetra Pak</h5>
                            <p class="text-muted">12 Months</p>
                        </Col>
                        <Col sm={6}>
                            <h4>Shipping | Loading:</h4>
                            <h5>For 200ML Tetra Pak</h5>
                            <p class="text-muted">40 Ft Ctn (28 Pallet) - 27*200 ML – 3600 Case  <br />20 Ft Ctn (14 Pallet) - 27*200 ML – 2250 Case</p>
                        </Col>
                    </Row>
                </Container>

            </Tab>


        </Tabs>























        <style jsx>{`

}
.grid-container {
  display: grid;
  grid-template-columns: auto auto auto;
  padding: 10px;
}

.grid-item {
  background-color: rgba(255, 255, 255, 0.8);
  padding: 20px;
  text-align: center;
}
  @media (max-width: 480px) {
    .hello {
      left: 30px;
      right: 30px;
      font-size: 18px;
      padding: 20px;
    }
    h1 {
      font-size: 28px;
    }
  }
.grid {
display: grid;
grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
grid-gap: 30px;
max-width: 1280px;
margin: 0 auto 30px;

}

/* Common style */
.grid figure {
position: relative;
float: left;
overflow: hidden;
margin: 10px 1%;
min-width: 320px;
max-width: 480px;
max-height: auto;
width: 100%;
background: #ffffff;
text-align: center;
cursor: pointer;
}

.grid figure img {
position: relative;
display: block;
min-height: auto;
max-width: auto;
opacity: 0.8;

}

.grid figure figcaption {
padding: 1em;
color: #000000;
font-size: 1.6em;
-webkit-backface-visibility: hidden;
backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
position: absolute;
top: 0;
left: 0;
width: 100%;
height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
z-index: 1000;
text-indent: 200%;
white-space: nowrap;
font-size: 0;
opacity: 0;
}

.grid figure p {
letter-spacing: 1px;
font-size: 55%;
}

/* Individual effects */

/
/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
background: #ffffff;
}

figure.effect-bubba img {
opacity: 1;
-webkit-transition: opacity 0.35s;
transition: opacity 0.35s;
}

figure.effect-bubba:hover img {
opacity: 0.4;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
position: absolute;
top: 20px;
right: 40px;
bottom: 20px;
left: 40px;
content: '';
opacity: 0;
-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
border-top: 1px solid #000000;
border-bottom: 1px solid #000000;
-webkit-transform: scale(0,1);
transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
border-right: 1px solid #000000;
border-left: 1px solid #000000;
-webkit-transform: scale(1,0);
transform: scale(1,0);
}

figure.effect-bubba h2 {
padding-top: 30%;
-webkit-transition: -webkit-transform 0.35s;
transition: transform 0.35s;
-webkit-transform: translate3d(0,-20px,0);
transform: translate3d(0,-20px,0);
}

figure.effect-bubba p {
padding: 20px 2.5em;
opacity: 0;
-webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
transition: opacity 0.35s, transform 0.35s;
-webkit-transform: translate3d(0,20px,0);
transform: translate3d(0,20px,0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
opacity: 1;
-webkit-transform: scale(1);
transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
opacity: 1;
-webkit-transform: translate3d(0,0,0);
transform: translate3d(0,0,0);
}

@media screen and (max-width: 50em) {
.content {
    padding: 0 10px;
    text-align: center;
}
.grid figure {
    display: inline-block;
    float: none;
    margin: 10px auto;
    width: 100%;
}
}
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { display: table; content: ''; }
.clearfix:after { clear: both; }

body {
background: #2f3238;
color: #fff;
font-weight: 500;
font-size: 2em;
font-family: 'Roboto', sans-serif;
}

a {
outline: none;
color: #3498db;
text-decoration: none;
}

a:hover, a:focus {
color: #528cb3;
}

section {
padding: 1em;
text-align: center;
}

.content {
margin: 0 auto;
max-width: 1000px;
}

.content > h2 {
clear: both;
margin: 0;
padding: 4em 1% 0;
color: #484B54;
font-weight: 800;
font-size: 1.5em;
}

.content > h2:first-child {
padding-top: 0em;
}

/* Header */
.codrops-header {
margin: 0 auto;
padding: 4em 1em;
text-align: center;
}

.codrops-header h1 {
margin: 0;
font-weight: 800;
font-size: 4em;
line-height: 1.3;
}

.codrops-header h1 span {
display: block;
padding: 0 0 0.6em 0.1em;
color: #74777b;
font-weight: 300;
font-size: 45%;
}

/* Demo links */
.codrops-demos {
clear: both;
padding: 1em 0 0;
text-align: center;
}

.content + .codrops-demos {
padding-top: 5em;
}

.codrops-demos a {
display: inline-block;
margin: 0 5px;
padding: 1em 1.5em;
text-transform: uppercase;
font-weight: bold;
}

.codrops-demos a:hover,
.codrops-demos a:focus,
.codrops-demos a.current-demo {
background: #3c414a;
color: #fff;
}

/* To Navigation Style */
.codrops-top {
width: 100%;
text-transform: uppercase;
font-weight: 800;
font-size: 0.69em;
line-height: 2.2;
}

.codrops-top a {
display: inline-block;
padding: 1em 2em;
text-decoration: none;
letter-spacing: 1px;
}

.codrops-top span.right {
float: right;
}

.codrops-top span.right a {
display: block;
float: left;
}

.codrops-icon:before {
margin: 0 4px;
text-transform: none;
font-weight: normal;
font-style: normal;
font-variant: normal;
font-family: 'codropsicons';
line-height: 1;
speak: none;
-webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
content: "001";
}

.codrops-icon-prev:before {
content: "004";
}

/* Related demos */
.related {
clear: both;
padding: 6em 1em;
font-size: 120%;
}

.related > a {
display: inline-block;
margin: 20px 10px;
padding: 25px;
border: 1px solid #4f7f90;
text-align: center;
}

.related a:hover {
border-color: #39545e;
}

.related a img {
max-width: 100%;
opacity: 0.8;
}

.related a:hover img,
.related a:active img {
opacity: 1;
}

.related a h3 {
margin: 0;
padding: 0.5em 0 0.3em;
max-width: 300px;
text-align: left;
}

/* Demo ad design */
body #cdawrap {
background: none;
top: 50px;
border: none;
}

body #cdawrap a {
color: #fff !important;
}

body #cda-remove {
color: #fff;
}

@media screen and (max-width: 25em) {
.codrops-header {
    font-size: 75%;
}
.codrops-icon span {
    display: none;
}
}

`}</style>






    </>
)