import React from 'react';
import { Container, Row, Col, Jumbotron, Button } from 'react-bootstrap';
import { FaAddressCard, FaGripLinesVertical } from 'react-icons/fa';
import { Helmet } from "react-helmet";


import barracuda from './assets/barracuda-ltd-1200-275.png'; // with require


export const AboutUs = () => (
    <>

        <Helmet>
            <title>Oberhof Drinks® About Us</title>
            <meta name="description" content="Company Information, Contact, Address" />
            <meta name="keywords" content="barracuda turk export, barracuda ic & dis ticaret, contact, address, company" />
        </Helmet>

        <div>
            <h2><FaAddressCard title="About Us" /> <FaGripLinesVertical /> About Us</h2>
        </div>

        <Container>
            <Row className="justify-content-md-center">
                <Col md="auto">
                    <h3 class="text-muted">Barracuda Turk Export® <br />Barracuda Domestic and Foreign Trade Limited</h3>
                    <p class="text-muted">
                    Barracuda Limited was found in the UK in January 2020 to operate in international trade field. 
                    Based on the 14 years experience of finance and 6 years experience of international trade area 
                    in Turkey, the founder Bilge Yildirim has moved the operations to the United Kingdom. Besides 
                    giving import & export support to companies, Barracuda also supplies beverages with It’s own 
                    registered trade mark ‘Oberhof Drinks’</p>

                    <p class="text-muted">Oberhof Drinks; Barracuda supplies non- alcoholic beverages 
                    (Fruit Juices, Smoothies, Organic Juice, Nectar) by its own brand ‘Oberhof Drinks’ 
                    Production is being made in Turkey. We are open to the companies who would like to be a distributor.</p>

                    <p class="text-muted">We offer our customers 2 option for the production of Oberhof Drinks® products; 
                    Austria and Turkey. Especially, our customers in United Kingdom, Middle East and Arabian Countries prefer production 
                    in Turkey since the logistic costs are more advantageous.</p>

                </Col>
            </Row>
        </Container>

        <Container>
            <Row className="justify-content-md-center">
                <Col sm={8}>
                    <img src={barracuda} alt="Barracuda Turk Export" />
                    <p class="text-muted">Barracuda Turk Export® entered the fruit juice market with its registered brand Oberhof Drinks® in the beverage group and our
                        goal is to become the preferred brand worldwide with its natural and high quality products.</p>
                    <p class="text-muted">Oberhof Drinks® Fruit Juices are bottled in Austria and Turkey, according to the requirements of International Food Standards (IFS).</p>
                </Col>
                <Col sm={4}>
                    <Jumbotron style={{
                        width: '100%',
                        margin: '40px auto 30px auto',
                        boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
                    }}>

                        <h3>Address || Contact</h3>
                        <p class="text-muted">Barracuda Domestic & Foreign Trade Ltd</p>
                        <p class="text-muted">7 Bell Yard, London  <br />  England, WC2A 2JR</p>
                        <p class="text-muted">info@oberhofdrinks.com</p>
                        <p class="text-muted">Company number: 12389616</p>
                        <center><Button variant="outline-secondary" href="https://barracuda.ltd" title="Web Site" target="_blank">Web Site</Button></center>

                    </Jumbotron>
                </Col>
            </Row>
        </Container>

        <style>{`

img {
  max-width: 100%;
}

`}</style>

    </>
)

