import React from 'react';
import { Tab, Tabs, Accordion, Card } from 'react-bootstrap'
import { FaGripLinesVertical, FaQuestion } from 'react-icons/fa';
import { Helmet } from "react-helmet";


export const Faq = () => (
  <>

    <Helmet>
      <title>Oberhof Drinks® F | A | Q</title>
      <meta name="description" content="Frequently Asked Questions" />
      <meta name="keywords" content="what, where, when, why, Oberhof Drinks" />
    </Helmet>


    <div>
      <h2><FaQuestion title="F | A | Q" /> <FaGripLinesVertical /> F | A | Q</h2>
    </div>

    <Tabs defaultActiveKey="profile" id="uncontrolled-tab-example">
      <Tab eventKey="homehow" title="| How |">


        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              How (Contact)?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">If you are the blessed Chuck Norris, no need to read the rest of it, obviously you are not then it is really so simple;
            just find the suitable communication type from below like email, then someone will get back to you ASAP.<br /><br />
                Either you may use the oldest smoke way but we are not very good to read it, just | still working on it.
</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              How (Shipping)?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Text class="text-muted">Our official logistic partner is Schenker-Arkas Company.</Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>




      </Tab>
      <Tab eventKey="what" title="| What |">




        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              What is Oberhof Drinks?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">Oberhof Drinks® is the brand which manufacture of Juice and Nectar
                  and more.
        </Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="1">
              Whats is the story of Oberhof Drinks?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="1">
              <Card.Text class="text-muted">We are not deeply sorry but we do not have any special story about Oberhof Drinks, still working on it.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="2">
              What happens when you drinks Oberhof Drinks?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="2">
              <Card.Text class="text-muted">We are not deeply sorry again but it is only Juice and can not help you to feel that you are jumping through the cool water from the hot sand.<br /><br /> So thus it feels you to is only Juice.
          So just drink and get back to what you were doing.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="3">
              What is the difference of Oberhof Drinks than any other brands?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="3">
              <Card.Text class="text-muted">C’mon, it is a juice, only juice, just a juice. Yes, at least it is healthier like any other juice but it won’t help you to be a Supermen nor something like that nor provides a healthy body.<br /><br /> We kindly advice you to start exercise,
          then gonna feel better after 2 weeks later, not before than.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="4">
              What kind of languages on the packages?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="4">
              <Card.Text class="text-muted">Standard languages are German, English, Arabic and Russian.</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="6">
              What is the Barcode Numbers?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="6">
              <Card.Text class="text-muted">All our products are manufacturing in Turkey & Austria</Card.Text>
            </Accordion.Collapse>
          </Card>
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="7">
              What is terms?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="7">
              <Card.Text class="text-muted">All our production is special production for related clients. It means when we got your order; we will start to produce your orders within 15 days, we do not have any stock.<br /><br /> So thus all production is specially producing for the client, in another way; tailor job. All our goods are maximum
          1 month old when you delivered and shipping included.</Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>



      </Tab>
      <Tab eventKey="where" title="| Where |">

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Where is our offices?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">We are growing day by day and on 2020 we are planning to have 7 more branches.
                        Actually we have three contact offices which in USA, Libya and Turkey.
        </Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>


      </Tab>
      <Tab eventKey="why" title="| Why |">

        <Accordion defaultActiveKey="0">
          <Card>
            <Accordion.Toggle as={Card.Header} eventKey="0">
              Why Oberhof Drinks?
    </Accordion.Toggle>
            <Accordion.Collapse eventKey="0">
              <Card.Text class="text-muted">In the meanwhile in our office; we are sitting
              behind our desks and answering the phones and writing emails like any other offices and feeling ourselves we are very important people and the world
      can not turn its around if we do not exist. But We are really very important people at least just like you so thus either our Brand is.<br /><br />
                Now you knew; how.<br /><br /> In fact; Why Oberhof Drinks®’s answer is itself. Our flexibility; We have been at stage since 2010 and We have finished one passport
                book within entries & sorties and second book is almost finished.  A proverb says who knows better; travels a lot or read a lot. Yes, we read a lot and travels a lot.
      Answer is flexibility, we are saying the way of success is flexibility and understanding each other. We can understand no matter you in what language you are speaking.<br /><br />
                Now you knew; why.

        </Card.Text>
            </Accordion.Collapse>
          </Card>
        </Accordion>





      </Tab>
    </Tabs>

  </>
)