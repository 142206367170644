import React from 'react';
import { FaLeaf, FaEye, FaUserTag, FaGripLinesVertical, FaHome, FaMortarPestle } from 'react-icons/fa';
import { FaBatteryFull, FaBatteryHalf, FaBatteryQuarter } from 'react-icons/fa';
import { Container, Row, Col, Button, Card, Jumbotron } from 'react-bootstrap'
import { Helmet } from "react-helmet";




import oberhofB from './assets/oberhofB.png'; // with require
import orangeJt2 from './assets/products/16.png'; // with require
import appleJt2 from './assets/products/2.png'; // with require
import pomegranateGlass from './assets/products/26.png'; // with require
import mangoGlass from './assets/products/10.png'; // with require

import appleJt1 from './assets/products/appleJt1.png'; // with require
import orangeNt1 from './assets/products/orangeNt1.png'; // with require
import mixedNt1 from './assets/products/mixedNt1.png'; // with require
import mangoJt1 from './assets/products/mangoJt1.png'; // with require


export const Home = () => (

    <>
        <Helmet>
            <title>Smoothie - Fruit Juice - Nectar - Flavored Drinks | Oberhof Drinks®</title>
            <meta name="description" content="Products (Smoothie - Juice - Nectar - Flavored Drinks) | FAQ | About | Language (en | de)" />
            <meta name="keywords" content="Oberhof Drinks, Smoothie, Juice, Nectar, Flavored Drinks, Fresh Goods, Accessibility, Sole Seller" />
        </Helmet>

        <div className="cover">
            <div className="hello">
                <h2><FaHome title="Home" /> <FaGripLinesVertical />Oberhof Drinks®</h2>
                <div><h3><FaMortarPestle title="Smoothie" /> <FaGripLinesVertical /> <FaBatteryFull title="Juice" /> <FaGripLinesVertical /> <FaBatteryHalf title="Nectar" /> <FaGripLinesVertical /> <FaBatteryQuarter title="Flavored Drinks" /></h3></div>
            </div>
        </div>
        <div className="let">
            <center><h2>Let's talk about product</h2></center>
            <p>We make Smoothie <FaMortarPestle title="Smoothie" /> | Juice <FaBatteryFull title="Juice" /> | Nectar <FaBatteryHalf title="Nectar" /> | Flavored Drinks <FaBatteryQuarter title="Flavored Drinks" /> and all our business is Juice.
        We manufacture our products in Turkey & Austria. And gives our
customers the following advantages.</p>
        </div>
        <Container>
            <Row>
                <Col sm={4}>
                    <Card style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '40px auto 30px auto',
                        boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
                    }}>

                        <Card.Header><h3><FaLeaf title="Fresh Goods" /></h3></Card.Header>
                        <Card.Body>
                            <Card.Title><h5>Fresh Goods</h5></Card.Title>
                            <Card.Text class="text-muted">We work only order based (not stock based) so thus you can be sure you will receive only fresh items, whenever and wherever, meaning.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '40px auto 30px auto',
                        boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
                    }}>

                        <Card.Header><h3><FaEye title="Accessibility" /></h3></Card.Header>
                        <Card.Body>
                            <Card.Title><h5>Accessibility</h5></Card.Title>
                            <Card.Text class="text-muted">From the moment the order email is approved, you can monitor the status online until the product reaches you, meaning.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
                <Col sm={4}>
                    <Card style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '40px auto 30px auto',
                        boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
                    }}>
                        <Card.Header><h3><FaUserTag title="Sole Seller" /></h3></Card.Header>
                        <Card.Body>
                            <Card.Title><h5>Sole Seller</h5></Card.Title>
                            <Card.Text class="text-muted">In principle we only work with one client in each region, so you can really concentrate on your business to increase your sales, meaning.</Card.Text>
                        </Card.Body>
                    </Card>
                </Col>
            </Row>
        </Container>
        <Jumbotron style={{
                        width: '100%',
                        textAlign: 'center',
                        margin: '40px auto 30px auto',
                        boxShadow: '0 5px 10px 2px rgba(0,0,0,0.25)',
                    }}>
                        <h3><FaMortarPestle title="Organic | Cold Pressed | Smoothie" /> || Organic 100% || Cold Pressed || Smoothie</h3>
                        <p class="text-muted">
                        We have added also 100% organic juices and smoothies to our product range. 
            These include apple juice, quince juice, grape juice, pear juice, purple carrot juice, 
            apricot juice, melon juice, pomegranate juice, watermelon juice, cherry juice, black mulberry juice, 
            fig juice, cranberry juice, plum juice, blackberry juice, fruit juice with protein, mixed fruit and vegetable juice, 
            lemonade fruit juice, organic fruit puree.</p> <p>All of them 100% Organic.</p></Jumbotron><br />
        <center><h2><FaGripLinesVertical /> <FaBatteryFull title="Juice" />  200ML <FaGripLinesVertical /></h2></center><br />
        <div className="grid">
            <figure className="effect-bubba">
                <h5>Orange Juice</h5>
                <img src={orangeJt2} alt="Orange Juice | Tetra Pak | 200ML" />
                <figcaption>
                    <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
            <figure className="effect-bubba">
                <h5>Apple Juice</h5>
                <img src={appleJt2} alt="Apple Juice | Tetra Pak | 200ML" />
                <figcaption>
                    <p>Tetra Pak | 200ML<br />1 Case: 27*200ML<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
            <figure className="effect-bubba">
                <h5>Pomegranate Juice</h5>
                <img src={pomegranateGlass} alt="Pomegranate Juice | Glass | 200ML" />
                <figcaption>
                    <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
            <figure className="effect-bubba">
                <h5>Mango Juice</h5>
                <img src={mangoGlass} alt="Mango Juice | Glass Bottle | 200ML" />
                <figcaption>
                    <p>Glass Bottle | 200ML<br />1 Case: 24*200ML<br />Shelf Life: 18 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
        </div>
        <center><Button variant="outline-success" href="/Products">VIEW ALL</Button></center>
       <br />
        <center><h2><FaGripLinesVertical /> <FaBatteryFull title="Juice" /> <FaBatteryHalf title="Nectar" /> 1L <FaGripLinesVertical /></h2></center><br />
        <div className="grid">

            <figure className="effect-bubba">
                <h5>Apple Juice</h5>
                <img src={appleJt1} alt="Apple Juice | Tetra Pak | 1L" />
                <figcaption>
                    <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
            <figure className="effect-bubba">
                <h5>Orange Nectar</h5>
                <img src={orangeNt1} alt="Orange Nectar | Tetra Pak | 1L" />
                <figcaption>
                    <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
            <figure className="effect-bubba">
                <h5>Mixed Fruit Nectar</h5>
                <img src={mixedNt1} alt="Mixed Fruit Nectar | Tetra Pak | 1L" />
                <figcaption>
                    <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
            <figure className="effect-bubba">
                <h5>Mango Juice</h5>
                <img src={mangoJt1} alt="Mango Juice | Tetra Pak | 1L" />
                <figcaption>
                    <p>Tetra Pak | 1L<br />1 Case: 12*1L<br />Shelf Life: 12 Months <br />Oberhof Drinks®</p>
                </figcaption>
            </figure>
        </div>
        <center><Button variant="outline-success" href="/Products">VIEW ALL</Button></center>





        <style jsx>{`
      .cover {
        position: relative;
        min-height: 600px;
        background: url(${oberhofB}) no-repeat center center;
        background-size: cover;
      }

      .hello {
        position: absolute;
        top: 190px;
        left: 10px;
        background: transparent;
        padding: 30px;

      }
      .hello h1 {
        margin: 0 0 10px 0;

      }
      a.view-more {
        text-transform: uppercase;
        font-size: 20px;
      }
      .some-products {
        text-align: center;
        padding: 30px 0;
        margin-bottom: 60px;
      }
      }
      .let {
        text-align: center;
        padding: 10px 0;
        margin-bottom: 30px;

      }
	.grid-container {
  	display: grid;
  	grid-template-columns: auto auto auto;
  	padding: 10px;
	}

	.grid-item {
  	background-color: rgba(255, 255, 255, 0.8);
  	padding: 20px;
  	text-align: center;
	}
      @media (max-width: 480px) {
        .hello {
          left: 30px;
          right: 30px;
          font-size: 18px;
          padding: 20px;
        }
        h1 {
          font-size: 28px;
        }
      }
.grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  grid-gap: 30px;
  max-width: 1280px;
  margin: 0 auto 30px;
  
}

/* Common style */
.grid figure {
    position: relative;
    float: left;
    overflow: hidden;
    margin: 10px 1%;
    min-width: 320px;
    max-width: 480px;
    max-height: auto;
    width: 100%;
    background: #ffffff;
    text-align: center;
    cursor: pointer;
}

.grid figure img {
    position: relative;
    display: block;
    min-height: auto;
    max-width: auto;
    opacity: 0.8;
    
}

.grid figure figcaption {
    padding: 1em;
    color: #000000;
    font-size: 1.6em;
    -webkit-backface-visibility: hidden;
    backface-visibility: hidden;
}

.grid figure figcaption::before,
.grid figure figcaption::after {
    pointer-events: none;
}

.grid figure figcaption,
.grid figure figcaption > a {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
}

/* Anchor will cover the whole item by default */
/* For some effects it will show as a button */
.grid figure figcaption > a {
    z-index: 1000;
    text-indent: 200%;
    white-space: nowrap;
    font-size: 0;
    opacity: 0;
}

.grid figure p {
    letter-spacing: 1px;
    font-size: 55%;
}

/* Individual effects */

/
/*---------------*/
/***** Bubba *****/
/*---------------*/

figure.effect-bubba {
    background: #ffffff;
}

figure.effect-bubba img {
    opacity: 1;
    -webkit-transition: opacity 0.35s;
    transition: opacity 0.35s;
}

figure.effect-bubba:hover img {
    opacity: 0.4;
}

figure.effect-bubba figcaption::before,
figure.effect-bubba figcaption::after {
    position: absolute;
    top: 20px;
    right: 40px;
    bottom: 20px;
    left: 40px;
    content: '';
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
}

figure.effect-bubba figcaption::before {
    border-top: 1px solid #000000;
    border-bottom: 1px solid #000000;
    -webkit-transform: scale(0,1);
    transform: scale(0,1);
}

figure.effect-bubba figcaption::after {
    border-right: 1px solid #000000;
    border-left: 1px solid #000000;
    -webkit-transform: scale(1,0);
    transform: scale(1,0);
}

figure.effect-bubba h2 {
    padding-top: 30%;
    -webkit-transition: -webkit-transform 0.35s;
    transition: transform 0.35s;
    -webkit-transform: translate3d(0,-20px,0);
    transform: translate3d(0,-20px,0);
}

figure.effect-bubba p {
    padding: 20px 2.5em;
    opacity: 0;
    -webkit-transition: opacity 0.35s, -webkit-transform 0.35s;
    transition: opacity 0.35s, transform 0.35s;
    -webkit-transform: translate3d(0,20px,0);
    transform: translate3d(0,20px,0);
}

figure.effect-bubba:hover figcaption::before,
figure.effect-bubba:hover figcaption::after {
    opacity: 1;
    -webkit-transform: scale(1);
    transform: scale(1);
}

figure.effect-bubba:hover h2,
figure.effect-bubba:hover p {
    opacity: 1;
    -webkit-transform: translate3d(0,0,0);
    transform: translate3d(0,0,0);
}

@media screen and (max-width: 50em) {
    .content {
        padding: 0 10px;
        text-align: center;
    }
    .grid figure {
        display: inline-block;
        float: none;
        margin: 10px auto;
        width: 100%;
    }
}
*, *:after, *:before { -webkit-box-sizing: border-box; box-sizing: border-box; }
.clearfix:before, .clearfix:after { display: table; content: ''; }
.clearfix:after { clear: both; }

body {
    background: #2f3238;
    color: #fff;
    font-weight: 500;
    font-size: 2em;
    font-family: 'Roboto', sans-serif;
}

a {
    outline: none;
    color: #3498db;
    text-decoration: none;
}

a:hover, a:focus {
    color: #528cb3;
}

section {
    padding: 1em;
    text-align: center;
}

.content {
    margin: 0 auto;
    max-width: 1000px;
}

.content > h2 {
    clear: both;
    margin: 0;
    padding: 4em 1% 0;
    color: #484B54;
    font-weight: 800;
    font-size: 1.5em;
}

.content > h2:first-child {
    padding-top: 0em;
}

/* Header */
.codrops-header {
    margin: 0 auto;
    padding: 4em 1em;
    text-align: center;
}

.codrops-header h1 {
    margin: 0;
    font-weight: 800;
    font-size: 4em;
    line-height: 1.3;
}

.codrops-header h1 span {
    display: block;
    padding: 0 0 0.6em 0.1em;
    color: #74777b;
    font-weight: 300;
    font-size: 45%;
}

/* Demo links */
.codrops-demos {
    clear: both;
    padding: 1em 0 0;
    text-align: center;
}

.content + .codrops-demos {
    padding-top: 5em;
}

.codrops-demos a {
    display: inline-block;
    margin: 0 5px;
    padding: 1em 1.5em;
    text-transform: uppercase;
    font-weight: bold;
}

.codrops-demos a:hover,
.codrops-demos a:focus,
.codrops-demos a.current-demo {
    background: #3c414a;
    color: #fff;
}

/* To Navigation Style */
.codrops-top {
    width: 100%;
    text-transform: uppercase;
    font-weight: 800;
    font-size: 0.69em;
    line-height: 2.2;
}

.codrops-top a {
    display: inline-block;
    padding: 1em 2em;
    text-decoration: none;
    letter-spacing: 1px;
}

.codrops-top span.right {
    float: right;
}

.codrops-top span.right a {
    display: block;
    float: left;
}

.codrops-icon:before {
    margin: 0 4px;
    text-transform: none;
    font-weight: normal;
    font-style: normal;
    font-variant: normal;
    font-family: 'codropsicons';
    line-height: 1;
    speak: none;
    -webkit-font-smoothing: antialiased;
}

.codrops-icon-drop:before {
    content: "001";
}

.codrops-icon-prev:before {
    content: "004";
}

/* Related demos */
.related {
    clear: both;
    padding: 6em 1em;
    font-size: 120%;
}

.related > a {
    display: inline-block;
    margin: 20px 10px;
    padding: 25px;
    border: 1px solid #4f7f90;
    text-align: center;
}

.related a:hover {
    border-color: #39545e;
}

.related a img {
    max-width: 100%;
    opacity: 0.8;
}

.related a:hover img,
.related a:active img {
    opacity: 1;
}

.related a h3 {
    margin: 0;
    padding: 0.5em 0 0.3em;
    max-width: 300px;
    text-align: left;
}

/* Demo ad design */
body #cdawrap {
    background: none;
    top: 50px;
    border: none;
}

body #cdawrap a {
    color: #fff !important;
}

body #cda-remove {
    color: #fff;
}

@media screen and (max-width: 25em) {
    .codrops-header {
        font-size: 75%;
    }
    .codrops-icon span {
        display: none;
    }
}

    `}</style>






    </>
)
